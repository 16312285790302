import { lazy } from "react";
import { Route } from "react-router-dom";

const CVEditor = lazy(() => import("./CVEditorPage"));

export const CVEditorRouter = (
  <>
    <Route path="/cv-editor" element={<CVEditor />} />
    <Route path="/cv-editor/:cvId" element={<CVEditor />} />
  </>
);
