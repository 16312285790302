import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { Loader } from "./components/Loader/Loader";
import { authRouter } from "./features/core/Auth/Auth.router";
import { companyStructureRouter } from "./features/core/CompanyStructure/CompanyStructure.router";
import { documentListRouter } from "./features/core/Documents/Document.router";
import { termsRouter } from "./features/core/Terms/Terms.router";
import { usageRouter } from "./features/core/Usage/Usage.router";
import userRouter from "./features/core/Users/User.router";
import { portfolioDesignEditorRouter } from "./features/profolio/Portfolio/PortfolioDesignEditor/views/PortfolioDesignEditor.router";
import { portfolioEditorRouter } from "./features/profolio/Portfolio/PortfolioEditor/views/PortfolioEditor.router";
import { portfolioRouter } from "./features/profolio/Portfolio/views/Portfolio.router";
import { sharedLinksEditorRouter } from "./features/profolio/SharedLinks/SharedLinksEditor/SharedLinksEditor.router";
import { sharedLinksListRouter } from "./features/profolio/SharedLinks/SharedLinksList/SharedLinksList.router";
import { sharedLinksViewPageRouter } from "./features/profolio/SharedLinks/SharedLinksViewPage/SharedLinksViewPage.router";
import { showcaseEditorRouter } from "./features/profolio/Showcase/ShowCaseEditor/views/ShowcaseEditor.router";
import { showcaseListRouter } from "./features/profolio/Showcase/ShowcaseList/ShowcaseList.router";
import { CVEditorRouter } from "./features/prohiring/CVEditor/view/CVEditor.router";
import { cvRouter } from "./features/prohiring/CVListPage/view/CV.router";
import { clientCompanyRouter } from "./features/prohiring/ClientCompany/ClientCompany.router";
import { hiringCircleRouter } from "./features/prohiring/HiringCircle/HiringCircle.router";
import { vacancyRouter } from "./features/prohiring/Vacancy/Vacancy.router";
import { appType } from "./services/appConfig";
import { isExtendedUrl } from "./services/url";

const RedirectPage = lazy(() => import("./features/core/Redirect/RedirectPage"));
const ContactsPage = lazy(() => import("./features/core/Contacts/Contacts"));
const SecuritySettings = lazy(() => import("./features/core/SecuritySettings/SecuritySettings"));
const NotificationsPage = lazy(() => import("./features/core/Notifications/NotificationsPage"));
const WelcomePage = lazy(() => import("./features/core/WelcomePage/WelcomePage"));
const ErrorPage = lazy(() => import("./components/ErrorPage/ErrorPage"));
const UserInfoPage = lazy(() => import("./features/core/Users/MyProfile/UserInfoPage"));
const DashboardHiring = lazy(() => import("./features/prohiring/Dashboard/DashboardHiring"));
const DashboardProfolio = lazy(() => import("./features/profolio/Dashboard/DashboardProfolio"));
const ChangePasswordPage = lazy(
  () => import("./features/core/Auth/ChangePassword/ChangePasswordPage"),
);

const CompanyInfoPage = lazy(() => import("./features/core/CompanyInfo/CompanyInfoPage"));
const DevInfo = lazy(() => import("./features/core/DevInfo/DevInfo"));

const AppRouter = () => {
  const dashboardComponent = appType === "hiring" ? <DashboardHiring /> : <DashboardProfolio />;
  const previewPage = isExtendedUrl ? dashboardComponent : <WelcomePage />;
  document.title = appType === "hiring" ? "Prohiring" : "Profolio";
  if (appType === "hiring") {
    // todo change favicon
    document.querySelector("link[rel*='icon']")?.setAttribute("href", "/logoProHiring192.png");
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={previewPage} />
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/settings/company-info" element={<CompanyInfoPage />} />
        <Route path="/settings/security" element={<SecuritySettings />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/git-summary" element={<DevInfo />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        {usageRouter}
        {hiringCircleRouter}
        {userRouter}
        {showcaseEditorRouter}
        {authRouter}
        {companyStructureRouter}
        {termsRouter}
        {portfolioRouter}
        {showcaseListRouter}
        {portfolioEditorRouter}
        {portfolioDesignEditorRouter}
        {cvRouter}
        {CVEditorRouter}
        {vacancyRouter}
        {clientCompanyRouter}
        {documentListRouter}
        {sharedLinksListRouter}
        {sharedLinksEditorRouter}
        {sharedLinksViewPageRouter}
        <Route path="*" element={<ErrorPage code={"404"} />} />
      </Routes>
    </Suspense>
  );
};
export default AppRouter;
