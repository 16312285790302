import { query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

import { UserInfo } from "@common/user.types";

import db from "../../services/firebase/firestore";
import { useAuth } from "../useAuth";
import { UsersContext } from "./usersContext";

const recruitersSystemRoles = ["Administrator", "Recruiter", "Profolio administrator"];

export function useProviderUsers(): UsersContext {
  const [users, setUsers] = useState<Record<string, UserInfo | undefined>>({});
  const [isActiveUser, setIsActiveUser] = useState<boolean>(true);

  const [emails, setEmails] = useState<string[]>([]);
  const [recruitersEmails, setRecruitersEmails] = useState<string[]>([]);

  const auth = useAuth();

  const [allUsers, loadingUsers] = useCollection(auth.uid ? query(db.collections.users) : null);
  const myEmail = auth.userInfo.email || "";

  const isClientForHiring = users[auth.uid]?.systemRole === "Client for Hiring";

  useEffect(() => {
    if (allUsers && !loadingUsers) {
      const emails: string[] = [];
      const newRecruitersEmails: string[] = [];
      let users: { [id: string]: UserInfo } = {};

      allUsers.docs.forEach((userDoc) => {
        const userInfo = userDoc.data();
        const isActive = userInfo.isActive;
        const systemRole = userInfo.systemRole || "";

        if (recruitersSystemRoles.includes(systemRole)) {
          newRecruitersEmails.push(userInfo.email);
        }
        const isMyProfile = myEmail === userInfo.email;
        if (isMyProfile) {
          setIsActiveUser(isActive);
        }

        emails.push(userInfo.email);

        if (isActive) {
          users = { ...users, [userDoc.id]: userInfo };
        }
      });

      setRecruitersEmails(newRecruitersEmails);
      setUsers(users);
      setEmails(emails);
    }
  }, [allUsers, loadingUsers]);

  return {
    isActiveUser,
    isClientForHiring,
    users,
    emails,
    recruitersEmails,
    loading: loadingUsers || !auth.uid || !allUsers || (!users[auth.uid] && !auth.isSuperAdmin),
  };
}
