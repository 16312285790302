import { lazy } from "react";
import { Route } from "react-router-dom";

const SharedLinksViewPage = lazy(() => import("./SharedLinksViewPage"));

export const sharedLinksViewPageRouter = (
  <>
    <Route path="/sharedLink/:sharedLinkId" element={<SharedLinksViewPage />} />
    <Route path="/sharedLink/:sharedLinkId/:subPageId" element={<SharedLinksViewPage />} />
  </>
);
