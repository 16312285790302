import { getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import db from "src/services/firebase/firestore";

import { CompanyFeaturesSettings } from "@common/db.types";

import { useAuth } from "../useAuth";
import { FeaturesSettingsContext } from "./featuresSettingsContext";

const initData: CompanyFeaturesSettings = {
  recruitmentBase: true,
  portfolioBranding: true,
  portfolioBase: true,
  portfolioFeedback: true,
};

export function useProviderFeaturesSettings(): FeaturesSettingsContext {
  const [featuresSettings, setFeaturesSettings] = useState<CompanyFeaturesSettings>(initData);
  const [featuresSettingsLoading, setFeaturesSettingsLoading] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (auth.uid) syncWithDatabase();
  }, [auth.uid]);

  const syncWithDatabase = async () => {
    setFeaturesSettingsLoading(true);
    const docFromServer = await getDoc(db.documents.config.featuresSettings);
    const dataFromServer = docFromServer.data();
    setFeaturesSettingsLoading(false);
    if (!dataFromServer) {
      return;
    }
    setFeaturesSettings(dataFromServer);
  };

  const saveSettings = async (newData: Partial<CompanyFeaturesSettings>) => {
    await setDoc(db.documents.config.featuresSettings, {
      ...featuresSettings,
      ...newData,
    });
    syncWithDatabase();
  };

  return {
    featuresSettings: featuresSettings || initData,
    featuresSettingsLoading: featuresSettingsLoading,
    saveSettings,
  };
}
