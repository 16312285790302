import { lazy } from "react";
import { Route } from "react-router-dom";

const AllUsersPage = lazy(() => import("./UserList/AllUsersPage"));
const EditUserPage = lazy(() => import("./EditUserPage/EditUserPage"));
const InvitePageStart = lazy(() => import("./InvitePageStart/InvitePageStart"));
const InviteClientPage = lazy(() => import("./InviteClientPage/InviteClientPage"));
const InviteEmployeePage = lazy(() => import("./InviteEmployeePage/InviteEmployeePage"));

export const userRouter = (
  <>
    <Route path="/invite" element={<InvitePageStart />} />
    <Route path="/user-invite-client" element={<InviteClientPage />} />
    <Route path="/user-invite-employee" element={<InviteEmployeePage />} />

    <Route path="/user-corporate-info/:userId" element={<EditUserPage />} />
    <Route path="/users" element={<AllUsersPage />} />
  </>
);
export default userRouter;
