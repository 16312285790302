import React from "react";
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import AppRouter from "./AppRouter";
import { ThemeContextProvider } from "./components/theme";
import { CookiesBar } from "./features/core/CookiesBar/CookiesBar";
import { DevButton } from "./features/core/DevButton/DevButton";
import { Header } from "./features/core/Header/Header";
import { ClientCompaniesProvider } from "./hooks/ClientCompanies/ClientCompaniesProvider";
import { AccessProvider } from "./hooks/access/AccessProvider";
import { BackendProvider } from "./hooks/backend/BackendProvider";
import { ConfigProvider } from "./hooks/config/ConfigProvider";
import { CoreSettingsProvider } from "./hooks/coreSettings/CoreSettingsProvider";
import { FeaturesSettingsProvider } from "./hooks/featuresSettings/FeaturesSettingsProvider";
import { MessagesProvider } from "./hooks/messages/MessagesProvider";
import { AuthProvider } from "./hooks/useAuth";
import { CvProvider } from "./hooks/useCv/CvProvider";
import { DialogProvider } from "./hooks/useDialog";
import { useHotjar } from "./hooks/useHotjar";
import { NotificationProvider } from "./hooks/useNotification";
import { CvPreviewProvider } from "./hooks/usePreviewCv/CvProvider";
import { UserSettingsProvider } from "./hooks/useUserSettings";
import { UsersProvider } from "./hooks/users/UsersProvider";
import { isDevEnvironment } from "./services/url";

export default function App(): JSX.Element {
  useHotjar();
  return (
    <ThemeContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationProvider>
          <DialogProvider>
            <BrowserRouter>
              <AuthProvider>
                <ConfigProvider>
                  <CoreSettingsProvider>
                    <BackendProvider>
                      <UserSettingsProvider>
                        <AccessProvider>
                          <UsersProvider>
                            <ClientCompaniesProvider>
                              <MessagesProvider>
                                <CvProvider>
                                  <CvPreviewProvider>
                                    <FeaturesSettingsProvider>
                                      <Header />
                                      <AppRouter />
                                      {isDevEnvironment && <DevButton />}
                                      <CookiesBar />
                                    </FeaturesSettingsProvider>
                                  </CvPreviewProvider>
                                </CvProvider>
                              </MessagesProvider>
                            </ClientCompaniesProvider>
                          </UsersProvider>
                        </AccessProvider>
                      </UserSettingsProvider>
                    </BackendProvider>
                  </CoreSettingsProvider>
                </ConfigProvider>
              </AuthProvider>
            </BrowserRouter>
          </DialogProvider>
        </NotificationProvider>
      </LocalizationProvider>
    </ThemeContextProvider>
  );
}
