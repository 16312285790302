import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../../assets/styles/colors";
import { GetTypeOfProperty } from "../../utils/type.utils";

const style: ComponentsOverrides["MuiFormLabel"] = {
  root: {
    color: COLORS["neutral-8"],
    fontSize: "0.875rem",
    marginLeft: "4px",
    fontFamily: "var(--font-family)",
    ".MuiFormLabel-asterisk": {
      color: COLORS["functional-error"],
    },
    "&.Mui-disabled": {
      color: COLORS["neutral-8"],
    },
    "&.Mui-focused": {
      color: COLORS["neutral-8"],
    },
    "&.Mui-error": {
      color: COLORS["neutral-8"],
    },
  },
};

export const customFormLabel: GetTypeOfProperty<Components, "MuiFormLabel"> = {
  styleOverrides: style,
};
