import { Components, ComponentsOverrides, ComponentsVariants } from "@mui/material";

import { COLORS } from "../../assets/styles/colors";
import { GetTypeOfProperty } from "../../utils/type.utils";

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    iconSquareSmall: true;
    iconSquareLarge: true;
  }
}

const variants: ComponentsVariants["MuiButton"] = [
  {
    props: { size: "iconSquareSmall" },
    style: {
      paddingInlineEnd: "14px",
      paddingInlineStart: "14px",
    },
  },
  {
    props: { size: "iconSquareLarge" },
    style: {
      height: "3.4rem",
      paddingInlineEnd: "18px",
      paddingInlineStart: "18px",
    },
  },
  {
    props: { variant: "outlined" },
    style: {
      "&:focus": {
        border: `1px solid ${COLORS["theme-primary"]}`,
        boxShadow: `0px 0px 0px 2px ${COLORS["neutral-1"]}, 0px 0px 0px 4px ${COLORS["theme-primary"]}`,
        borderRadius: "4px",
      },
    },
  },

  {
    props: { variant: "contained" },
    style: {
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },

  {
    props: { size: "large" },
    style: {
      height: "3.4rem",
      paddingInlineEnd: "24px",
      paddingInlineStart: "24px",
    },
  },
  {
    props: { variant: "text" },
    style: {
      "&:focus": {
        background: COLORS["neutral-5"],
        border: "none",
        boxShadow: "none",
        borderRadius: "4px",
      },
    },
  },
];

const style: ComponentsOverrides["MuiButton"] = {
  outlined: {
    backgroundColor: COLORS["neutral-1"],
    boxShadow: "none",
    "&:hover": {
      backgroundColor: COLORS["neutral-4"],
    },
    "&:active": {
      backgroundColor: COLORS["neutral-5"],
      boxShadow: "none",
    },
    "&:disabled": {
      color: COLORS["neutral-6"],
      backgroundColor: COLORS["neutral-1"],
    },
  },
  text: {
    color: COLORS["theme-primary"],
    "&:disabled": {
      color: COLORS["neutral-6"],
      background: "none",
    },
  },
  root: {
    height: "3rem",
    paddingInlineEnd: "16px",
    paddingInlineStart: "16px",
    padding: "12px 16px",
    minWidth: 0,
    fontSize: "1rem",
    lineHeight: "1rem",
    "@media (max-width: 600px)": {
      height: "2.5rem",
      fontSize: "0.7rem",
      lineHeight: "0.8rem",
    },
    fontWeight: "normal",
    fontStyle: "normal",
    textTransform: "none",
    fontFamily: "var(--font-family)",
    "& .MuiSvgIcon-root": {
      height: "1.5rem",
      width: "1.5rem",
      margin: 0,
      paddingInlineStart: 0,
    },
    "&:active": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: `0 0 0 2px ${COLORS["neutral-1"]}, 0 0 0 4px ${COLORS["theme-primary"]}`,
    },
    "&:disabled": {
      color: COLORS["neutral-1"],
      backgroundColor: COLORS["neutral-6"],
    },
  },
};

export const customButton: GetTypeOfProperty<Components, "MuiButton"> = {
  variants: variants,
  styleOverrides: style,
};
