export const TEST_SELECTOR = {
  company: {
    infoForm: {
      nameInput: "company-info-form-name",
      phoneInput: "company-info-form-phone",
      emailInput: "company-info-form-email",
      submitButton: "company-info-form-submit-button",
    },
  },
  cookiesBar: {
    acceptButton: "cookies-bar-accept-button",
  },
  devTool: {
    mainTogglerButton: "dev-tool-button",
    resetButton: "dev-tool-button-reset",
    generateButton: "dev-tool-button-generate",
    adminLoginButton: "dev-tool-button-login-as-admin",
  },
  auth: {
    signInButton: "auth-form-sign-in-button",
  },
  header: {
    companyInfoDropdown: "user-header-company-info-dropdown",
    companyInfoMenu: "user-header-company-info",
    dashboardMenu: "user-header-dashboard",
    userProfile: "user-profile",
    signInActionButton: "sign-in-action-Button",
    showcasePage: "user-header-showcase",
    portfolioDropdown: "user-header-portfolio",
    createAccountActionButton: "create-account-action-button",
    aboutUsLink: "header-about-us-link",
  },
  createAccount: {
    emailInput: "create-account-email",
    actionCheckbox: "create-account-checkbox",
    createAccountPageActionButton: "create-account-page-action-button",
    setPassword: {
      passwordInput: "create-account-password-input",
      passwordRepetitionInput: "create-account-password-repetition-input",
      submitButton: "create-account-submit-button",
    },
  },
  showcase: {
    nameInput: "showcase-general-name",
    industryInput: "showcase-general-industry",
    mainCoverImageInputContainer: "showcase-cover-image-input",
    mainRoleInput: "showcase-general-main-role",
    createButton: "showcase-create-button",
    secondStepButton: "showcase-second-step",
  },
  components: {
    fileUploader: "component-file-image",
    saveDraftButton: "component-save-draft-button",
  },
} as const;

export const getTestDataIdAttribute = (name: string | undefined) => {
  if (!name) {
    return {};
  }

  return {
    "data-test-id": name,
  };
};

export const getTestDataIdSelector = (name: string) => {
  return `[data-test-id="${name}"]`;
};
