import { ReactNode } from "react";

import { backendContext } from "./backendContext";
import { useProviderBackend } from "./useProviderBackend";

export function BackendProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceBackend = useProviderBackend();

  return (
    <backendContext.Provider value={maintenanceBackend}>{props.children}</backendContext.Provider>
  );
}
