import { ReactNode } from "react";

import { featuresSettingsContext } from "./featuresSettingsContext";
import { useProviderFeaturesSettings } from "./useProviderFeaturesSettings";

export function FeaturesSettingsProvider(props: { children: ReactNode }): JSX.Element {
  const featuresSettingsHook = useProviderFeaturesSettings();

  return (
    <featuresSettingsContext.Provider value={featuresSettingsHook}>
      {props.children}
    </featuresSettingsContext.Provider>
  );
}
