import { lazy } from "react";
import { Route } from "react-router-dom";

const SharedLinksListPage = lazy(() => import("./SharedLinksListPage"));

export const sharedLinksListRouter = (
  <>
    <Route path="/sharedLinks-list" element={<SharedLinksListPage />} />
  </>
);
