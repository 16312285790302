import { ReactNode } from "react";

import { messagesContext } from "./messagesContext";
import { useProviderMessages } from "./useProviderMessages";

export function MessagesProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceMessages = useProviderMessages();

  return (
    <messagesContext.Provider value={maintenanceMessages}>
      {props.children}
    </messagesContext.Provider>
  );
}
