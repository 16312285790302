import { DocumentReference, setDoc } from "firebase/firestore";
import { Context, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { UserSettings } from "@common/user.types";

import db from "../services/firebase/firestore";
import { useAuth } from "./useAuth";

interface UserSettingsContext extends UserSettings {
  loading: boolean;
  updateUserSettings: (newSettings: Partial<UserSettings>) => void;
}

const userSettingsContext: Context<UserSettingsContext> = createContext<UserSettingsContext>({
  loading: false,
  showShareImageDialog: true,
  updateUserSettings: (data) => {
    console.log("userSettingsContext ", data);
  },
});

function useProviderUserSettings(): UserSettingsContext {
  const [dataPoint, setDataPoint] = useState<DocumentReference<UserSettings>>();
  const { uid } = useAuth();
  useEffect(() => {
    if (dataPoint || !uid) {
      return;
    } else {
      setDataPoint(db.getUserSettingsDataPoint(uid));
    }
  }, [dataPoint, uid]);
  const [userSettings, loading] = useDocumentData(dataPoint);

  return {
    loading: !dataPoint || loading,
    showShareImageDialog: userSettings?.showShareImageDialog ?? true,
    emailMessages: userSettings?.emailMessages ?? "Instantly",
    updateUserSettings: async (data) => {
      if (!dataPoint) {
        return;
      }
      await setDoc(dataPoint, data, { merge: true });
    },
  };
}

export function UserSettingsProvider(props: { children: ReactNode }): JSX.Element {
  const userSettings = useProviderUserSettings();
  return (
    <userSettingsContext.Provider value={userSettings}>
      {props.children}
    </userSettingsContext.Provider>
  );
}
export const useUserSettings = (): UserSettingsContext => useContext(userSettingsContext);
