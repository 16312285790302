import { lazy } from "react";
import { Route } from "react-router-dom";

const ShowcaseEditorPage = lazy(() => import("./ShowcaseEditorPage/ShowcaseEditorPage"));

export const showcaseEditorRouter = (
  <>
    <Route path="/showcase-editor" element={<ShowcaseEditorPage />} />
    <Route path="/showcase-editor/:showcaseId" element={<ShowcaseEditorPage />} />
  </>
);
