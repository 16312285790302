import { useDocumentData } from "react-firebase-hooks/firestore";

import db from "../../services/firebase/firestore";
import { ConfigContext } from "./configContext";

export function useProviderConfig(): ConfigContext {
  const [companyInfoDB] = useDocumentData(db.documents.config.companyInfo);
  const companyEmail = companyInfoDB?.email;

  const globalConfig = {
    adminEmail: "info@profolio.dev",
    supportEmail: "info@profolio.dev",
    infoEmail: "info@profolio.dev",
    internetAddress: "http://profolio.dev",
  };

  return {
    loading: false,
    companyConfig: {
      adminEmail: companyEmail || globalConfig.supportEmail,
      supportEmail: companyEmail || globalConfig.supportEmail,
      infoEmail: companyEmail || globalConfig.supportEmail,
      internetAddress: "http://company-name.profolio.com",
    },
    globalConfig,
  };
}
