import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../../assets/styles/colors";
import { GetTypeOfProperty } from "../../utils/type.utils";

const style: ComponentsOverrides["MuiFormHelperText"] = {
  root: {
    fontSize: "0.875rem",
    marginLeft: "4px",
    fontFamily: "var(--font-family)",
    color: COLORS["neutral-8"],
    "&.Mui-error": {
      color: COLORS["functional-error"],
    },
  },
};

export const customHelperText: GetTypeOfProperty<Components, "MuiFormHelperText"> = {
  styleOverrides: style,
};
