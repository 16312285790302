import { lazy } from "react";
import { Route } from "react-router-dom";

const ShowcaseListPage = lazy(() => import("./ShowcaseListPage"));

export const showcaseListRouter = (
  <>
    <Route path="/showcases" element={<ShowcaseListPage />} />
  </>
);
