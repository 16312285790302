import { lazy } from "react";
import { Route } from "react-router-dom";

const ClientCompanyEditorPage = lazy(
  () => import("./ClientCompanyEditorPage/ClientCompanyEditorPage"),
);
const ClientCompanyListPage = lazy(() => import("./ClientCompanyListPage/ClientCompanyListPage"));

export const clientCompanyRouter = (
  <>
    <Route path="/clientCompany-list" element={<ClientCompanyListPage />} />
    <Route path="/clientCompany-editor" element={<ClientCompanyEditorPage />} />
    <Route path="/clientCompany-editor/:clientCompanyId" element={<ClientCompanyEditorPage />} />
  </>
);
