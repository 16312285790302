import { lazy } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../../../hooks/useAuth";

const UserHeader = lazy(() => import("./UserHeader"));
const StaticHeader = lazy(() => import("./StaticHeader"));

export function Header(): JSX.Element {
  const { isAuthorized } = useAuth();
  const location = useLocation();
  const skipHeaderUrls = ["/portfolio/", "/sharedLink/"];

  const isNeedToSkipHeader = skipHeaderUrls.some((url) => location.pathname.startsWith(url));

  if (isNeedToSkipHeader) {
    return <></>;
  }

  return isAuthorized ? <UserHeader /> : <StaticHeader />;
}
