import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../../assets/styles/colors";
import { GetTypeOfProperty } from "../../utils/type.utils";

const style: ComponentsOverrides["MuiInputBase"] = {
  input: {
    "&:-webkit-autofill": {
      boxShadow: `inset 0 0 0 30px ${COLORS["input-autofocus"]}`,
      "& + button": {
        marginLeft: "-52px",
      },
    },
  },
  root: {
    backgroundColor: COLORS["neutral-1"],
    fontFamily: "var(--font-family)",
    "label + &": {
      marginTop: "1.5rem",
    },
    ".MuiTablePagination-root label + &": {
      marginTop: "inherit",
    },
  },
};

export const customInput: GetTypeOfProperty<Components, "MuiInputBase"> = {
  styleOverrides: style,
};
