import { FC } from "react";

import { Button } from "@mui/material";

import { TEST_SELECTOR, getTestDataIdAttribute } from "../../tests/selectors";
import { CookiesBarContainer } from "./style";

interface CookiesBarProps {
  isOpen: boolean;
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  onAcceptClick: () => void;
}

export const CookiesBar: FC<CookiesBarProps> = function ({
  isOpen = false,
  termsOfServiceUrl,
  privacyPolicyUrl,
  onAcceptClick,
}) {
  if (!isOpen) {
    return <></>;
  }
  return (
    <CookiesBarContainer>
      <p>
        We use cookies to enhance your experience with our service.
        <a href={termsOfServiceUrl} target="_blank" data-testid="cookies-bar-terms-of-service-link">
          Terms of Service
        </a>{" "}
        and the{" "}
        <a href={privacyPolicyUrl} target="_blank" data-testid="cookies-bar-privacy-policy-link">
          Privacy Policy.
        </a>
      </p>

      <Button
        {...getTestDataIdAttribute(TEST_SELECTOR.cookiesBar.acceptButton)}
        variant="contained"
        className="action-button"
        onClick={onAcceptClick}
        data-testid="cookies-bar-action-button"
        sx={{
          padding: "0 20px",
          height: "2rem",
        }}
      >
        Accept All Cookies
      </Button>
    </CookiesBarContainer>
  );
};
