import { useDocumentData } from "react-firebase-hooks/firestore";

import db from "../../services/firebase/firestore";
import { useAuth } from "../useAuth";
import { CoreSettingsContext } from "./coreSettingsContext";

const gradeList = ["Junior", "Middle", "Senior", "Lead", "Principal", "Staff"];
export function useProviderCoreSettings(): CoreSettingsContext {
  const auth = useAuth();
  const [coreSettings, loading] = useDocumentData(
    auth.uid ? db.documents.config.coreSettings : null,
  );
  return {
    coreSettings: coreSettings,
    gradeList: gradeList,
    loading: loading,
  };
}
