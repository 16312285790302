import { lazy } from "react";
import { Route } from "react-router-dom";

const UsagePage = lazy(() => import("./UsagePage"));

export const usageRouter = (
  <>
    <Route path="/usage" element={<UsagePage />} />
  </>
);
export default usageRouter;
