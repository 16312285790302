import { ReactNode } from "react";

import { configContext } from "./configContext";
import { useProviderConfig } from "./useProviderConfig";

export function ConfigProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceConfig = useProviderConfig();

  return (
    <configContext.Provider value={maintenanceConfig}>{props.children}</configContext.Provider>
  );
}
