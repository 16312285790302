import { Context, createContext } from "react";

import { FunctionsDefinitionForClient } from "./types";

export interface BackendContext extends FunctionsDefinitionForClient {}

export const backendContext: Context<BackendContext> = createContext<BackendContext>({
  company: {
    registerCompany: async () => {
      throw new Error("registerCompany is no implemented");
    },
    confirmCompany: async () => {
      throw new Error("confirmCompany is no implemented");
    },
    deleteCompany: async () => {
      throw new Error("deleteCompany is no implemented");
    },
    deleteCompanyVerification: async () => {
      throw new Error("deleteCompanyVerification is no implemented");
    },
  },
  showcase: {
    showcaseParser: async () => {
      throw new Error("showcaseParser is no implemented");
    },
    showcaseSearch: async () => {
      throw new Error("showcaseSearch is no implemented");
    },
  },
  ai: {
    aiPrompt: async () => {
      throw new Error("aiPrompt is no implemented");
    },
    imagePreviewUrl: async () => {
      throw new Error("previewImage is no implemented");
    },
  },
  cv: {
    parserCV: async () => {
      throw new Error("cvParser is no implemented");
    },
    cvSearch: async () => {
      throw new Error("cvSearch is no implemented");
    },
    clearCVVectorStore: async () => {
      throw new Error("clearCVVectorStore is no implemented");
    },
    getCVList: async () => {
      throw new Error("getCVList is no implemented");
    },
    generateVectorStore: async () => {
      throw new Error("generateVectorStore is no implemented");
    },
  },
  user: {
    getUserDomainByEmail: async () => {
      throw new Error("getUserDomainByEmail is no implemented");
    },
    resetPassword: () => {
      throw new Error("resetPassword is no implemented");
    },
    setPassword: () => {
      throw new Error("setPassword is no implemented");
    },
    isNeedSetPassword: () => {
      throw new Error("isNeedSetPassword is no implemented");
    },
    deleteUserResetPassword: () => {
      throw new Error("deleteUserResetPassword is no implemented");
    },
    inviteUser: () => {
      throw new Error("inviteUser is no implemented");
    },
  },
  devInfo: {
    clientConvertCommits: () => {
      throw new Error("clientConvertCommits is no implemented");
    },
  },
  invite: {
    getInvites: () => {
      throw new Error("getInvites is no implemented");
    },
    deleteInvite: () => {
      throw new Error("deleteInvite is no implemented");
    },
  },
  sharedLink: {
    getPublicShaderLinkPortfolio: () => {
      throw new Error("getPublicShaderLinkPortfolio is no implemented");
    },
    sendFeedback: () => {
      throw new Error("sendFeedback is no implemented");
    },
    sendEmail: () => {
      throw new Error("sendEmail is no implemented");
    },
  },
  vacancy: {
    sendNotifications: () => {
      throw new Error("sendNotifications is no implemented");
    },
    getVacanciesActions: () => {
      throw new Error("getVacanciesActions is no implemented");
    },
  },
  contact: {
    sendContactMessage: () => {
      throw new Error("getContacts is no implemented");
    },
  },
  notifications: {
    instantlyMessagesHandler: () => {
      throw new Error("instantlyMessagesHandler is no implemented");
    },
  },
});
