import { lazy } from "react";
import { Route } from "react-router-dom";

const SharedLinksEditorPage = lazy(() => import("./SharedLinksEditorPage"));

export const sharedLinksEditorRouter = (
  <>
    <Route path="/sharedLinks-editor" element={<SharedLinksEditorPage />} />
    <Route path="/sharedLinks-editor/:sharedLinksId" element={<SharedLinksEditorPage />} />
  </>
);
