import { lazy } from "react";
import { Route } from "react-router-dom";

const PortfolioDesignEditorPage = lazy(() => import("./PortfolioDesignEditorPage"));

export const portfolioDesignEditorRouter = (
  <>
    <Route path="/portfolio-design-editor" element={<PortfolioDesignEditorPage />} />
  </>
);
