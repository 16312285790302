import { AccessActivities } from "../../../../common/db.types";

export const defaultAccess: Record<AccessActivities, boolean> = {
  contactSupport: false,
  getNotificationAboutRelevantVacancies: false,
  signUp: false,
  resetPassword: false,
  changePassword: false,
  signIn: false,
  viewDashboard: false,
  updateDashboard: false,
  viewCompanyInfo: false,
  updateCompanyInfo: false,
  inviteUser: false,
  inviteClient: false,
  viewOwnPersonalInfo: false,
  viewOtherPersonalInfo: false,
  updateOwnPersonalInfo: false,
  updateOtherPersonalInfo: false,
  viewOwnUserCorporateInfo: false,
  viewOtherUserCorporateInfo: false,
  updateOwnUserCorporateInfo: false,
  updateOtherUserCorporateInfo: false,
  viewOwnUserSystemInfo: false,
  viewOtherUserSystemInfo: false,
  updateOwnUserSystemInfo: false,
  updateOtherUserSystemInfo: false,
  activateAndDeactivateUser: false,
  createDepartment: false,
  addUserToDepartment: false,
  viewDepartment: false,
  updateDepartment: false,
  deleteDepartment: false,
  addShowcase: false,
  viewOwnShowcases: false,
  viewOtherShowcases: false,
  updateOwnShowcase: false,
  updateOtherShowcase: false,
  copyOwnShowcase: false,
  copyOtherShowcase: false,
  deleteOwnShowcase: false,
  deleteOtherShowcase: false,
  saveOwnDraftShowcase: false,
  saveOtherDraftShowcase: false,
  sendOwnShowcaseForApproval: false,
  sendOtherShowcaseForApproval: false,
  sendShowcaseToDelete: false,
  approveOrDeclineShowcase: false,
  viewShowcaseHistory: false,
  addCV: false,
  viewOwnCV: false,
  viewOtherCV: false,
  updateOwnCV: false,
  updateOtherCV: false,
  publishOwnCV: false,
  publishOtherCV: false,
  rejectCV: false,
  deleteOwnCV: false,
  deleteOtherCV: false,
  shareCV: false,
  addDocument: false,
  viewDocument: false,
  updateDocument: false,
  deleteAnyDocument: false,
  signDocument: false,
  generateNDA: false,
  viewNDA: false,
  updateNDA: false,
  deleteNDA: false,
  sendNDA: false,
  signNDA: false,
  setupTemplateForPortfolios: false,
  createPortfolio: false,
  viewPortfolio: false,
  updatePortfolio: false,
  deletePortfolio: false,
  sharePortfolioLink: false,
  viewGlobalHiringCircle: false,
  editGlobalHiringCircle: false,
  viewVacancyHiringCircle: false,
  editVacancyHiringCircle: false,
  viewVacancies: false,
  editVacancy: false,
  viewInternalNotes: false,
  editInternalNotes: false,
  viewUsage: false,
};
