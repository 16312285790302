import { lazy } from "react";
import { Route } from "react-router-dom";

const VacancyEditorPage = lazy(() => import("./VacancyEditor/VacancyEditorPage"));
const VacancyListPage = lazy(() => import("./VacancyListPage/VacancyListPage"));
const VacancyViewPage = lazy(() => import("./VacancyView/VacancyViewPage"));
const VacancyCvBoard = lazy(() => import("../VacancyCvBoard/VacancyCvBoardPage"));

export const vacancyRouter = (
  <>
    <Route path="/vacancy-list" element={<VacancyListPage />} />
    <Route path="/vacancy-editor/:vacancyId" element={<VacancyEditorPage />} />
    <Route path="/vacancy-view/:vacancyId" element={<VacancyViewPage />} />
    <Route path="/vacancy-cv-board/:vacancyId" element={<VacancyCvBoard />} />
  </>
);
