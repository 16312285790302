import { SnapshotOptions } from "@firebase/firestore-types";
import FirebaseFirestore from "@google-cloud/firestore";
import { collection, doc } from "firebase/firestore";

import { ClientCompany } from "@common/clientCompany.types";
import { CVDocument, CVPreview, CvGlobalStat } from "@common/cv.types";
import {
  CompanyConfigHiringInfo,
  CompanyFeaturesSettings,
  CompanyInfo,
  CoreProjectSettings,
  DepartmentInfo,
} from "@common/db.types";
import { Document } from "@common/document.types";
import { Message } from "@common/message.types";
import { Portfolio } from "@common/portfolio.types";
import { SharedLink } from "@common/sharedLinks.types";
import { ShowcaseData } from "@common/showcase.types";
import { UsageAiRecord } from "@common/usage.types";
import { UserInfo, UserSettings } from "@common/user.types";
import { Vacancy } from "@common/vacancy.types";

import { companyName } from "../url";
import { firestore } from "./index";

interface FirestoreDataConverter<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore(model: T): any;

  fromFirestore(snapshot: unknown, options?: SnapshotOptions): T;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const converter = <T>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (snap: FirebaseFirestore.QueryDocumentSnapshot) => snap.data() as T,
});

export const dataPointCollection = <T>(collectionPath: string) =>
  // eslint-disable-next-line
  collection(firestore, collectionPath).withConverter(converter<T>() as FirestoreDataConverter<T>);

export const dataPointDoc = <T>(documentPath: string) =>
  // eslint-disable-next-line
  doc(firestore, documentPath).withConverter(converter<T>() as FirestoreDataConverter<T>);
const db = {
  collections: {
    users: dataPointCollection<UserInfo>(`companies/${companyName}/users`),
    showcases: dataPointCollection<ShowcaseData>(`companies/${companyName}/showcases`),
    portfolios: dataPointCollection<Portfolio>(`companies/${companyName}/portfolios`),
    cv: dataPointCollection<CVDocument>(`companies/${companyName}/cv`),
    cvPreview: dataPointCollection<CVPreview>(`companies/${companyName}/cvPreview`),
    departments: dataPointCollection<DepartmentInfo>(`companies/${companyName}/departments`),
    vacancies: dataPointCollection<Vacancy>(`companies/${companyName}/vacancies`),
    documents: dataPointCollection<Document>(`companies/${companyName}/documents`),
    messages: dataPointCollection<Message>(`companies/${companyName}/messages`),
    sharedLinks: dataPointCollection<SharedLink>(`companies/${companyName}/sharedLinks`),
    clientCompanies: dataPointCollection<ClientCompany>(`companies/${companyName}/clientCompanies`),
    usage: dataPointCollection<UsageAiRecord>(`companies/${companyName}/usage`),
  },
  documents: {
    config: {
      companyInfo: dataPointDoc<CompanyInfo>(`companies/${companyName}/config/companyInfo`),
      featuresSettings: dataPointDoc<CompanyFeaturesSettings>(
        `companies/${companyName}/config/featuresSettings`,
      ),
      hiringInfo: dataPointDoc<CompanyConfigHiringInfo>(
        `companies/${companyName}/config/hiringInfo`,
      ),
      coreSettings: dataPointDoc<CoreProjectSettings>(`settings/core`),
    },
    cvStats: dataPointDoc<CvGlobalStat>(`companies/${companyName}/stats/cv`),
  },
  getUserSettingsDataPoint(userId: string) {
    return dataPointDoc<UserSettings>(
      `companies/${companyName}/users/${userId}/privateInfo/settings`,
    );
  },
  getUserInfoDataPoint(userId: string) {
    return dataPointDoc<UserInfo>(`companies/${companyName}/users/${userId}`);
  },
};

export default db;
