import { Context, createContext } from "react";

import { CVPreview } from "@common/cv.types";

export interface CvPreviewElement {
  id: string;
  data: CVPreview;
}

export interface CvPreviewContext {
  cvData: Record<string, CVPreview>; // id -> CVPreview
  getCvData: (ids: string[]) => Promise<CvPreviewElement[]>;
}

export const cvPreviewContext: Context<CvPreviewContext> = createContext<CvPreviewContext>({
  cvData: {},
  getCvData: () => Promise.resolve([]),
});
