import { deleteDoc, doc, query, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

import { Message } from "@common/message.types";

import db from "../../services/firebase/firestore";
import { useAuth } from "../useAuth";
import { MessagesContext } from "./messagesContext";

export function useProviderMessages(): MessagesContext {
  const auth = useAuth();

  const [allMessages] = useCollection(auth.uid ? query(db.collections.messages) : null);
  const [readMessageCount, setReadMessageCount] = useState(0);

  useEffect(() => {
    const localhostMessagesRead = parseInt(localStorage.getItem("portfolio_messagesRead") || "0");
    setReadMessageCount(localhostMessagesRead);
  }, []);

  const messages =
    allMessages?.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    })) || [];

  const myMessages = messages
    .filter(
      (message) =>
        message.data.ccUserIds.includes(auth.uid) ||
        message.data.authorUserId === auth.uid ||
        message.data?.ccEmails?.includes(auth.userInfo.email || ""),
    )
    .filter((message) => !message.data.isDraft || message.data.authorUserId === auth.uid)
    .sort((a, b) => b.data.createdAt - a.data.createdAt);

  const deleteMessage = async (messageId: string) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this message?");
    if (!confirmDelete) {
      return;
    }

    await deleteDoc(doc(db.collections.messages, messageId));
  };

  const createMessage = async (newMessage: Message) => {
    const uid = doc(db.collections.messages);
    await setDoc(uid, newMessage);
  };

  const updateMessage = async (messageId: string, message: Message) => {
    await setDoc(doc(db.collections.messages, messageId), message);
  };

  return {
    myMessages,
    readMessageCount,
    updateReadMessageCount: (messageCount: number) => {
      setReadMessageCount(messageCount);
      localStorage.setItem("portfolio_messagesRead", `${messageCount}`);
    },
    messages,
    deleteMessage,
    createMessage,
    updateMessage,
  };
}
