import { documentId, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import db from "src/services/firebase/firestore";
import { chunkArray } from "src/utils/array";

import { CVDocument } from "@common/cv.types";

import { CVElement, CvContext } from "./cvContext";

export function useProviderCv(): CvContext {
  const [cvData, setCvData] = useState<Record<string, CVDocument>>({});

  const getCvData = async (cvIds: string[]): Promise<CVElement[]> => {
    if (cvIds.length === 0) {
      return [];
    }
    const newCvIds = cvIds.filter((id) => !cvData[id]);

    if (newCvIds.length !== cvIds.length) {
      console.log("Some CVs are already loaded | useCv");
    }

    const cvIdChunks = chunkArray(newCvIds, 10);

    const allNewCvDocs = await Promise.all(
      cvIdChunks.map(async (idChunk) => {
        const involvedCvQuery = query(db.collections.cv, where(documentId(), "in", idChunk));
        return await getDocs(involvedCvQuery);
      }),
    );

    const resultMap: Record<string, CVDocument> = {};

    allNewCvDocs.forEach((cvDocs) => {
      cvDocs.docs.forEach((cvDoc) => {
        const cvData = cvDoc.data();
        resultMap[cvDoc.id] = cvData;
      });
    });

    setCvData((prev) => ({ ...prev, ...resultMap }));

    return cvIds.map((id) => ({ id, data: resultMap[id] || cvData[id] }));
  };

  return {
    cvData,
    getCvData,
  };
}
