import { Context, createContext } from "react";

import { Message } from "@common/message.types";

import { MessageDoc } from "./types";

export interface MessagesContext {
  messages: MessageDoc[];
  myMessages: MessageDoc[];
  readMessageCount: number;
  updateReadMessageCount: (messagesRead: number) => void;
  deleteMessage: (messageId: string) => void;
  createMessage: (message: Message) => void;
  updateMessage: (messageId: string, message: Message) => void;
}

export const messagesContext: Context<MessagesContext> = createContext<MessagesContext>({
  messages: [],
  myMessages: [],
  readMessageCount: 0,
  updateReadMessageCount: () => {
    return;
  },
  deleteMessage: () => {
    return;
  },
  createMessage: () => {
    return;
  },
  updateMessage: () => {
    return;
  },
});
