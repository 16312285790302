import { ReactNode } from "react";

import { clientCompaniesContext } from "./clientCompaniesContext";
import { useProviderClientCompanies } from "./useProviderClientCompanies";

export function ClientCompaniesProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceClientCompanies = useProviderClientCompanies();

  return (
    <clientCompaniesContext.Provider value={maintenanceClientCompanies}>
      {props.children}
    </clientCompaniesContext.Provider>
  );
}
