import { ReactNode } from "react";

import { coreSettingsContext } from "./coreSettingsContext";
import { useProviderCoreSettings } from "./useProviderCoreSettings";

export function CoreSettingsProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceCoreSettings = useProviderCoreSettings();

  return (
    <coreSettingsContext.Provider value={maintenanceCoreSettings}>
      {props.children}
    </coreSettingsContext.Provider>
  );
}
