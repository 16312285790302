import { FC, useState } from "react";

import { CookiesBar as CookiesBarComponent } from "../../../components/CookiesBar/CookiesBar";
import { TERMS_URLS } from "../Terms/Terms.router";

const storageKey = "cookies-config";

interface CookiesConfigInfo {
  needToShowBanner: boolean;
}

const storageData = localStorage.getItem(storageKey);
const initCookiesConfig: CookiesConfigInfo = {
  ...{ needToShowBanner: true },
  ...JSON.parse(storageData || "{}"),
};

export const CookiesBar: FC = function () {
  const [cookiesConfig, setCookiesConfig] = useState(initCookiesConfig);

  const onAcceptClick = () => {
    const newConfig = { ...cookiesConfig, ...{ needToShowBanner: false } };
    localStorage.setItem(storageKey, JSON.stringify(newConfig));
    setCookiesConfig(newConfig);
  };

  const skipUrls = ["/portfolio/", "/sharedLink/"];

  const isNeedToSkip = skipUrls.some((url) => location.pathname.startsWith(url));

  if (isNeedToSkip) {
    return <></>;
  }

  return (
    <CookiesBarComponent
      isOpen={cookiesConfig.needToShowBanner}
      privacyPolicyUrl={TERMS_URLS.privacyPolicy}
      termsOfServiceUrl={TERMS_URLS.termsOfService}
      onAcceptClick={onAcceptClick}
    />
  );
};
