import { Context, createContext } from "react";

export interface ConfigContext {
  loading: boolean;
  companyConfig: {
    adminEmail: string;
    supportEmail: string;
    infoEmail: string;
    internetAddress: string;
  };
  globalConfig: {
    adminEmail: string;
    supportEmail: string;
    infoEmail: string;
    internetAddress: string;
  };
}

export const configContext: Context<ConfigContext> = createContext<ConfigContext>({
  loading: true,
  companyConfig: {
    adminEmail: "admin@company.com",
    supportEmail: "support@company.com",
    infoEmail: "info@company.com",
    internetAddress: "http://company-name.profolio.com",
  },
  globalConfig: {
    adminEmail: "info@profolio.dev",
    supportEmail: "info@profolio.dev",
    infoEmail: "info@profolio.dev",
    internetAddress: "http://profolio.dev",
  },
});
