import { ReactNode } from "react";
import ErrorPage from "src/components/ErrorPage/ErrorPage";
import { Header } from "src/features/core/Header/Header";

import { Stack } from "@mui/material";

import { useProviderUsers } from "./useProviderUsers";
import { usersContext } from "./usersContext";

export function UsersProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceUsers = useProviderUsers();

  const isActiveUser = maintenanceUsers.isActiveUser;

  if (!isActiveUser) {
    const userDisabledMessage = "Your account is disabled. Please contact your administrator.";
    return (
      <Stack gap={"30px"}>
        <Header />
        <ErrorPage code={"403"} message={userDisabledMessage} />
      </Stack>
    );
  }

  return <usersContext.Provider value={maintenanceUsers}>{props.children}</usersContext.Provider>;
}
