import { query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

import { ClientCompany } from "@common/clientCompany.types";

import db from "../../services/firebase/firestore";
import { useAuth } from "../useAuth";
import { ClientCompaniesContext } from "./clientCompaniesContext";

export function useProviderClientCompanies(): ClientCompaniesContext {
  const [clientCompanies, setClientCompanies] = useState<Record<string, ClientCompany | undefined>>(
    {},
  );

  const auth = useAuth();

  const [allClientCompanies, loadingClientCompanies] = useCollection(
    auth.uid ? query(db.collections.clientCompanies) : null,
  );

  useEffect(() => {
    if (allClientCompanies && !loadingClientCompanies) {
      const clientCompanies = allClientCompanies.docs.reduce((acc, clientCompanyDoc) => {
        const clientCompanyInfo = clientCompanyDoc.data();

        return { ...acc, [clientCompanyDoc.id]: clientCompanyInfo };
      }, {});

      setClientCompanies(clientCompanies);
    }
  }, [allClientCompanies, loadingClientCompanies]);

  return {
    clientCompanies,
  };
}
