import * as Sentry from "@sentry/react";
import "normalize.css";
import React from "react";
import { createRoot } from "react-dom/client";

import { StyledEngineProvider } from "@mui/material/styles";

import App from "./App";
import "./assets/styles/globals.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { isDevEnvironment } from "./services/url";

if (!isDevEnvironment) {
  Sentry.init({
    dsn: "https://a0734d9be422ab76e8faf84137e956b7@o4506187426103296.ingest.sentry.io/4506187428265984",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
    ignoreErrors: ["ResizeObserver loop completed with undelivered notifications."],
  });
}

const container = document.getElementById("root");
const root = createRoot(container!); // eslint-disable-line
root.render(
  <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StyledEngineProvider>,
);

serviceWorkerRegistration.register();
