import { httpsCallable } from "firebase/functions";

import { AIPromptRequest, AIPromptResponse } from "@server/api/ai/controller/aiPrompt";
import { ImageRequest, ImageResponse } from "@server/api/ai/controller/imageGenerator";
import {
  ConfirmCompanyRequest,
  ConfirmCompanyResponse,
} from "@server/api/company/services/confirmCompanyService";
import {
  DeleteCompanyRequest,
  DeleteCompanyResponse,
} from "@server/api/company/services/deleteCompanyService";
import {
  DeleteCompanyVerificationRequest,
  DeleteCompanyVerificationResponse,
} from "@server/api/company/services/deleteCompanyVerification";
import {
  RegisterCompanyRequest,
  RegisterCompanyResponse,
} from "@server/api/company/services/registerCompanyService";
import {
  ContactMessage,
  ContactMessageResult,
} from "@server/api/contact/services/sendContactMessage";
import { ClearVectorStoreRequest } from "@server/api/cv/controller/clearCVVectorStore";
import { GenerateVectorStoreRequest } from "@server/api/cv/controller/generateVectorStore";
import { CVSearchRequest, CVSearchResponse } from "@server/api/cv/controller/search";
import { GetCVListRequest, GetCVListResponse } from "@server/api/cv/services/convertCVtoCVList";
import {
  ConvertCommitsRequest,
  ConvertCommitsResponse,
} from "@server/api/devInfo/controller/clientConvertCommits";
import { DeleteInviteRequest } from "@server/api/invite/controller/deleteInvite";
import { GetInvitesRequest, GetInvitesResponse } from "@server/api/invite/controller/getInvites";
import {
  SharedLinkRequest,
  SharedLinkResponse,
} from "@server/api/sharedLinks/controller/getPublicShaderLinkPortfolio";
import { SendEmailRequest, SendEmailResponse } from "@server/api/sharedLinks/controller/sendEmail";
import {
  FeedbackResponse,
  SendFeedbackRequest,
} from "@server/api/sharedLinks/controller/sendFeedback";
import {
  ShowcaseSearchRequest,
  ShowcaseSearchResponse,
} from "@server/api/showcases/controller/search";
import {
  ShowcaseParseRequest,
  ShowcaseParseResponse,
} from "@server/api/showcases/controller/showcaseParser";
import {
  DeleteUserResetPasswordRequest,
  DeleteUserResetPasswordResponse,
} from "@server/api/users/controller/deleteUserResetPassword";
import {
  GetUserDomainByEmailRequest,
  GetUserDomainByEmailResponse,
} from "@server/api/users/controller/getUserDomainByEmail";
import { InviteUserRequest, InviteUserResponse } from "@server/api/users/controller/inviteUser";
import {
  IsNeedToResetPasswordRequest,
  IsNeedToResetPasswordResponse,
} from "@server/api/users/controller/isNeedSetPassword";
import {
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "@server/api/users/controller/resetPassword";
import { SetPasswordRequest } from "@server/api/users/controller/setPassword";
import {
  GetVacanciesActionsRequest,
  GetVacanciesActionsResponse,
} from "@server/api/vacancy/controller/getVacanciesActions";
import {
  SendNotificationRequest,
  SendNotificationResponse,
} from "@server/api/vacancy/controller/sendNotifications";

import { functions } from "../../services/firebase";
import { BackendContext } from "./backendContext";

function createHttpsCallable<TRequest, TResponse>(
  functionName: string,
  options?: { timeout: number },
) {
  return async (data: TRequest): Promise<TResponse> => {
    const cloudFunction = httpsCallable<TRequest, TResponse>(
      functions,
      `functions-${functionName}`,
      options,
    );
    return (await cloudFunction(data)).data;
  };
}

export function useProviderBackend(): BackendContext {
  return {
    company: {
      registerCompany: createHttpsCallable<RegisterCompanyRequest, RegisterCompanyResponse>(
        "company-registerCompany",
      ),
      confirmCompany: createHttpsCallable<ConfirmCompanyRequest, ConfirmCompanyResponse>(
        "company-confirmCompany",
      ),
      deleteCompany: createHttpsCallable<DeleteCompanyRequest, DeleteCompanyResponse>(
        "company-deleteCompany",
      ),
      deleteCompanyVerification: createHttpsCallable<
        DeleteCompanyVerificationRequest,
        DeleteCompanyVerificationResponse
      >("company-deleteCompanyVerification"),
    },
    showcase: {
      showcaseSearch: createHttpsCallable<ShowcaseSearchRequest, ShowcaseSearchResponse>(
        "showcase-showcaseSearch",
      ),
      showcaseParser: createHttpsCallable<ShowcaseParseRequest, ShowcaseParseResponse>(
        "showcase-showcaseParser",
      ),
    },
    ai: {
      aiPrompt: createHttpsCallable<AIPromptRequest, AIPromptResponse>("ai-aiPrompt"),
      imagePreviewUrl: createHttpsCallable<ImageRequest, ImageResponse>("ai-imagePreviewUrl"),
    },
    cv: {
      parserCV: createHttpsCallable<void, void>("cv-parserCV"),
      cvSearch: createHttpsCallable<CVSearchRequest, CVSearchResponse>("cv-cvSearch"),
      clearCVVectorStore: createHttpsCallable<ClearVectorStoreRequest, void>(
        "cv-clearCVVectorStore",
      ),
      getCVList: createHttpsCallable<GetCVListRequest, GetCVListResponse>("cv-getCVList"),
      generateVectorStore: createHttpsCallable<GenerateVectorStoreRequest, void>(
        "cv-generateVectorStore",
      ),
    },
    user: {
      getUserDomainByEmail: createHttpsCallable<
        GetUserDomainByEmailRequest,
        GetUserDomainByEmailResponse
      >("user-getUserDomainByEmail"),
      resetPassword: createHttpsCallable<ResetPasswordRequest, ResetPasswordResponse>(
        "user-resetPassword",
      ),
      setPassword: createHttpsCallable<SetPasswordRequest, GetUserDomainByEmailResponse>(
        "user-setPassword",
      ),
      isNeedSetPassword: createHttpsCallable<
        IsNeedToResetPasswordRequest,
        IsNeedToResetPasswordResponse
      >("user-isNeedSetPassword"),
      deleteUserResetPassword: createHttpsCallable<
        DeleteUserResetPasswordRequest,
        DeleteUserResetPasswordResponse
      >("user-deleteUserResetPassword"),
      inviteUser: createHttpsCallable<InviteUserRequest, InviteUserResponse>("user-inviteUser"),
    },
    devInfo: {
      clientConvertCommits: createHttpsCallable<ConvertCommitsRequest, ConvertCommitsResponse>(
        "devInfo-clientConvertCommits",
        { timeout: 180000 },
      ),
    },
    invite: {
      getInvites: createHttpsCallable<GetInvitesRequest, GetInvitesResponse>("invite-getInvites"),
      deleteInvite: createHttpsCallable<DeleteInviteRequest, void>("invite-deleteInvite"),
    },
    sharedLink: {
      getPublicShaderLinkPortfolio: createHttpsCallable<SharedLinkRequest, SharedLinkResponse>(
        "sharedLink-getPublicShaderLinkPortfolio",
      ),
      sendFeedback: createHttpsCallable<SendFeedbackRequest, FeedbackResponse>(
        "sharedLink-sendFeedback",
      ),
      sendEmail: createHttpsCallable<SendEmailRequest, SendEmailResponse>("sharedLink-sendEmail"),
    },
    vacancy: {
      sendNotifications: createHttpsCallable<SendNotificationRequest, SendNotificationResponse>(
        "vacancy-sendNotifications",
      ),
      getVacanciesActions: createHttpsCallable<
        GetVacanciesActionsRequest,
        GetVacanciesActionsResponse
      >("vacancy-getVacanciesActions"),
    },
    contact: {
      sendContactMessage: createHttpsCallable<ContactMessage, ContactMessageResult>(
        "contact-sendContactMessage",
      ),
    },
    notifications: {
      instantlyMessagesHandler: createHttpsCallable<void, void>(
        "notifications-instantlyMessagesHandler",
      ),
    },
  };
}
