import { Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface LoaderProps {
  padding?: string;
  size?: string;
}
export function Loader({ padding = "25% auto", size }: LoaderProps) {
  return (
    <Container sx={{ display: "flex", justifyContent: "center", margin: `${padding}` }}>
      <CircularProgress size={size} />
    </Container>
  );
}
