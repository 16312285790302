import { Context, createContext } from "react";

import { CVDocument } from "@common/cv.types";

export interface CVElement {
  id: string;
  data: CVDocument;
}

export interface CvContext {
  cvData: Record<string, CVDocument>; // id -> CVDocument
  getCvData: (ids: string[]) => Promise<CVElement[]>;
}

export const cvContext: Context<CvContext> = createContext<CvContext>({
  cvData: {},
  getCvData: () => Promise.resolve([]),
});
