import { lazy } from "react";
import { Route } from "react-router-dom";

const HiringCirclePage = lazy(() => import("./HiringCirclePage"));

export const hiringCircleRouter = (
  <>
    <Route path="/hiring-circle" element={<HiringCirclePage />} />
  </>
);
