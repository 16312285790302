import { lazy } from "react";
import { Route } from "react-router-dom";

const AcceptInvite = lazy(() => import("./AcceptInvite"));
const ConfirmCompany = lazy(() => import("./ConfirmCompany"));
const CreateCompany = lazy(() => import("./CreateCompany/CreateCompany"));
const SignIn = lazy(() => import("./SignIn/SignIn"));
const AuthPage = lazy(() => import("./AuthPage"));
const CheckYourEmail = lazy(() => import("./CheckYourEmail"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const SetPassword = lazy(() => import("./SetPassword"));

export const authRouter = (
  <>
    <Route path="/sign-in" element={<AuthPage formComponent={<SignIn />} />} />
    <Route path="/sign-up" element={<AuthPage formComponent={<CreateCompany />} />} />
    <Route path="/confirm-company" element={<AuthPage formComponent={<ConfirmCompany />} />} />
    <Route path="/accept-invite" element={<AuthPage formComponent={<AcceptInvite />} />} />
    <Route path="/check-your-email" element={<AuthPage formComponent={<CheckYourEmail />} />} />
    <Route path="/reset-password" element={<AuthPage formComponent={<SetPassword />} />} />
    <Route
      path="/request-reset-password"
      element={<AuthPage formComponent={<ResetPassword />} />}
    />
  </>
);
