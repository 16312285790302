const baseThemeColors = {
  "theme-primary": "#1068eb",
  "theme-secondary": "#303d60",
  "neutral-1": "#ffffff",
  "neutral-2": "#f8f9fa",
  "neutral-3": "#f1f3f5",
  "neutral-4": "#e9ecef",
  "neutral-5": "#ced4da",
  "neutral-5-0-25": "rgba(206, 212, 218, 0.25)",
  "neutral-5-0-40": "rgba(206, 212, 218, 0.4)",
  "neutral-6": "#adb5bd",
  "neutral-7": "#6a7178",
  "neutral-8": "#4f575e",
  "neutral-9": "#272b30",
  "neutral-10": "#101213",
  "neutral-11": "#f3f6f8",
  "functional-error": "#dc2020",
  "functional-success": "#29823b",
  "functional-warning": "#e9a100",
  "functional-info": "#017aad",
  "input-autofocus": "#e7f0fe",
  "additional-additional-1": "#105fd5",
  "additional-additional-2": "#1055bb",
  bg: "#F8F9FA",
  "bg-header": "#FFFFFF",
  "bg-input": "#FFFFFF",
  "bg-input-disabled": "#EEF1F4",
  "bg-overlay": "rgba(39, 43, 48, 0.5)",
  "bg-row": "#F8F9FA",
  "bg-row-light": "#FFFFFF",
  "bg-notification": "#303D60",
  text: "#101213",
  "text-secondary": "#4F575E",
  "text-placeholder": "#6A7178",
  "text-disabled": "#ADB5BD",
  link: "#105FD5",
  "link-hovered": "#303D60",
  icon: "#6A7178",
  border: "#CED4DA",
  "border-focus": "#1068EB",
  divider: "#CED4DA",
  "portfolio-gradient": "linear-gradient(95.93deg, #E5F0FF 0%, #CFE2FF 100%)",
  "portfolio-cards-hovered": "#E8F4FF",
  "portfolio-cards-pressed": "#A8CAED",
  white: "#FFFFFF",
  "block-background": "#FFFFFF",
};

type ColorsName = keyof typeof baseThemeColors;
const colorKeys = Object.keys(baseThemeColors).map((key) => key as ColorsName);

const darkThemeColors: { [color in ColorsName]: string } = {
  ...baseThemeColors,
  "theme-primary": "#1068eb",
  "neutral-1": "#1A1E2B",
  "neutral-2": "#272b30",
  "neutral-5": "#CED4DA",
  "neutral-7": "#FFFFFF",
  "neutral-8": "#CED4DA",
  "neutral-9": "#272b30",
  "neutral-10": "#FFFFFF",
  "additional-additional-1": "#105fd5",
  "portfolio-gradient": "linear-gradient(95.93deg, #4F688D 0%, #284572 100%)",
  "portfolio-cards-pressed": "#50657C",
  "portfolio-cards-hovered": "#32404F",
  "block-background": "#1A1E2B",
};

export const COLORS_CSS_DECLARATION = `
:root {
${colorKeys.reduce((acc, colorKey) => {
  return `${acc}
    --color-${colorKey}: ${baseThemeColors[colorKey]};`;
}, "")}
}

html:has(.dark) {
  background-color: ${darkThemeColors["neutral-1"]};
}

.dark {
${colorKeys.reduce((acc, colorKey) => {
  return `${acc}
    --color-${colorKey}: ${darkThemeColors[colorKey]};`;
}, "")}
}
`;

export const COLORS = colorKeys.reduce((acc, colorKey) => {
  return {
    ...acc,
    [colorKey]: `var(--color-${colorKey})`,
  };
}, {}) as Record<ColorsName, string>;
