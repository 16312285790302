export function compare(a1: Array<string>, a2: Array<string>) {
  return a1.length == a2.length && a1.every((v, i) => v === a2[i]);
}

export function uniq(draftArray: string[]) {
  return draftArray.filter((x, i, a) => a.indexOf(x) == i);
}

export function chunkArray(fullArray: string[], size: number) {
  const chunks = [];
  for (let i = 0; i < fullArray.length; i += size) {
    chunks.push(fullArray.slice(i, i + size));
  }
  return chunks;
}
