export type AppType = "hiring" | "profolio" | "knowledgeBase";

export const appType = (process.env.REACT_APP_TYPE || "profolio") as AppType;
//export const appType = "profolio" as AppType;
//export const appType = "hiring" as AppType;

export const isHiringApp = appType === "hiring";
export const isProfolioApp = appType === "profolio";

const appNameRoutes: Record<AppType, string> = {
  hiring: "Prohiring",
  profolio: "Profolio",
  knowledgeBase: "Knowledge Base",
};

export const appName = appNameRoutes[appType];

export const getAppUrl = (appType: AppType, domainName: string) => {
  const stagingUrls: Record<AppType, string> = {
    hiring: `https://${domainName}.prohiring.red`,
    profolio: `https://${domainName}.profolio.red`,
    knowledgeBase: `https://${domainName}.profolio.red`,
  };

  const prodUrls: Record<AppType, string> = {
    hiring: `https://${domainName}.prohiring.dev`,
    profolio: `https://${domainName}.profolio.dev`,
    knowledgeBase: `https://${domainName}.profolio.dev`,
  };

  const isProd = process.env.REACT_APP_ENV_TYPE === "prod";
  const routeUrl = isProd ? prodUrls : stagingUrls;

  const url = routeUrl[appType];
  return url;
};
