import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../../assets/styles/colors";
import { GetTypeOfProperty } from "../../utils/type.utils";

const style: ComponentsOverrides["MuiOutlinedInput"] = {
  input: {
    display: "block!important",
    backgroundColor: COLORS["neutral-1"],
    padding: "10px 12px",
    "&::placeholder": {
      opacity: 1,
      color: COLORS["neutral-7"],
    },
    ":disabled": {
      backgroundColor: COLORS["neutral-3"],
      borderColor: COLORS["neutral-5"],
      color: COLORS["neutral-7"],
    },
  },
  multiline: {
    padding: 0,
  },
  root: {
    fontFamily: "var(--font-family)",
    "::-webkit-input-placeholder": {
      color: "red",
      overflow: "visible",
      opacity: "1!important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${COLORS["neutral-5"]}`,
      "&::placeholder": {
        overflow: "visible",
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid",
      borderColor: COLORS["theme-primary"],
    },
    "&.Mui-hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS["neutral-7"],
    },
    "&.Mui-error": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: COLORS["functional-error"],
      },
    },
  },
};

export const customOutlinedInput: GetTypeOfProperty<Components, "MuiOutlinedInput"> = {
  styleOverrides: style,
  defaultProps: {
    notched: false,
  },
};
