import { ReactNode } from "react";

import { cvPreviewContext } from "./useCvPreviewContext";
import { useProviderCvPreview } from "./useProviderCvPreview";

export function CvPreviewProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceCv = useProviderCvPreview();

  return (
    <cvPreviewContext.Provider value={maintenanceCv}>{props.children}</cvPreviewContext.Provider>
  );
}
