import { ReactNode } from "react";

import { cvContext } from "./cvContext";
import { useProviderCv } from "./useProviderCv";

export function CvProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceCv = useProviderCv();

  return <cvContext.Provider value={maintenanceCv}>{props.children}</cvContext.Provider>;
}
