import { Context, createContext } from "react";

import { CoreProjectSettings } from "@common/db.types";

export interface CoreSettingsContext {
  coreSettings?: CoreProjectSettings;
  gradeList: string[];
  loading: boolean;
}

export const coreSettingsContext: Context<CoreSettingsContext> = createContext<CoreSettingsContext>(
  {
    gradeList: [],
    loading: false,
  },
);
