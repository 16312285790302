export function toBase64(plainText: string) {
  return window.btoa(plainText);
}

export function fromBase64(base64: string) {
  return window.atob(base64);
}

export function fileFromBase64(
  base64File: string,
  filename: string,
  mimeType: string,
): Promise<File> {
  return fetch(base64File)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve((reader.result as string).split(";base64,").pop() as string);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}
