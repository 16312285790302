import { ReactNode } from "react";

import { accessContext } from "./accessContext";
import { useProviderAccess } from "./useProviderAccess";

export function AccessProvider(props: { children: ReactNode }): JSX.Element {
  const maintenanceAccess = useProviderAccess();

  return (
    <accessContext.Provider value={maintenanceAccess}>{props.children}</accessContext.Provider>
  );
}
