import { lazy } from "react";
import { Route } from "react-router-dom";

const PortfolioEditorPage = lazy(() => import("./PortfolioEditorPage/PortfolioEditorPage"));

export const portfolioEditorRouter = (
  <>
    <Route path="/portfolio-editor" element={<PortfolioEditorPage />} />
    <Route path="/portfolio-editor/:portfolioId" element={<PortfolioEditorPage />} />
  </>
);
