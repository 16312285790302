import { lazy } from "react";
import { Route } from "react-router-dom";

const CVListPage = lazy(() => import("./CVListPage"));

export const cvRouter = (
  <>
    <Route path="/cv-list" element={<CVListPage />} />
  </>
);
