import { lazy } from "react";
import { Route } from "react-router-dom";

const PortfolioListPage = lazy(() => import("./PortfolioListPage"));
const PortfolioPage = lazy(() => import("./PortfolioPage"));

export const portfolioRouter = (
  <>
    <Route path="/portfolios" element={<PortfolioListPage />} />
    <Route path="/portfolio/:portfolioId" element={<PortfolioPage />} />
    <Route path="/portfolio/:portfolioId/:showcaseId" element={<PortfolioPage />} />
  </>
);
