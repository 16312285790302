import { Context, ReactNode, createContext, forwardRef, useContext, useState } from "react";
import { COLORS } from "src/assets/styles/colors";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

interface NotificationParams {
  message: string | ReactNode;
  type: AlertColor;
  timeout?: number;
}

interface NotificationContext {
  showNotification: (options: NotificationParams) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  notificationMessage: string | ReactNode;
  type: AlertColor;
  timeout: number;
  setTimeout: (timeout: number) => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationContext: Context<NotificationContext> = createContext<NotificationContext>({
  showNotification: () => void 0,
  open: false,
  setOpen: () => void 0,
  notificationMessage: "",
  type: "success",
  timeout: 3000,
  setTimeout: () => void 0,
});

function useProvideNotification(): NotificationContext {
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string | ReactNode>("");
  const [type, setType] = useState<AlertColor>("success");
  const [timeout, setTimeout] = useState(3000);

  const showNotification = (options: NotificationParams) => {
    setNotificationMessage(options.message);
    setType(options.type);
    setOpen(true);

    if (options.timeout) {
      setTimeout(options.timeout);
    }
  };

  return {
    showNotification,
    open,
    setOpen,
    notificationMessage,
    type,
    timeout,
    setTimeout,
  };
}

const icons = {
  success: <CheckCircleIcon color={"success"} />,
  error: <ErrorIcon color={"error"} />,
  info: <InfoIcon color={"info"} />,
  warning: <WarningIcon color={"warning"} />,
};

export function NotificationProvider(props: { children: ReactNode }): JSX.Element {
  const provider = useProvideNotification();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    provider.setOpen(false);
    provider.setTimeout(3000);
  };

  const successNotification = (
    <Snackbar
      open={provider.open}
      autoHideDuration={provider.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        icon={icons[provider.type]}
        severity={provider.type}
        sx={{
          minWidth: "min(440px, calc(100vw - 100px))",
          backgroundColor: COLORS["theme-secondary"],
          marginRight: "48px",
          marginBottom: "48px",
          maxHeight: "128px",
          maxWidth: "440px",
          fontFamily: "var(--font-family)",
        }}
      >
        {provider.notificationMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <NotificationContext.Provider value={provider}>
      <>
        {props.children}
        {successNotification}
      </>
    </NotificationContext.Provider>
  );
}

export const useNotification = (): NotificationContext => useContext(NotificationContext);
