import { lazy } from "react";
import { Route } from "react-router-dom";

const PrivacyPolicyPage = lazy(() => import("./PrivacyPolicy"));
const TermsOfServicePage = lazy(() => import("./TermsOfService"));

export const TERMS_URLS = {
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
};

export const termsRouter = (
  <>
    <Route path={TERMS_URLS.privacyPolicy} element={<PrivacyPolicyPage />} />
    <Route path={TERMS_URLS.termsOfService} element={<TermsOfServicePage />} />
  </>
);
