import { DocumentReference } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { UserInfo } from "../../../../common/user.types";
import db from "../../services/firebase/firestore";
import { useAuth } from "../useAuth";
import { AccessContext } from "./accessContext";
import { defaultAccess } from "./data";

export function useProviderAccess(): AccessContext {
  const { uid, loading, isSuperAdmin } = useAuth();
  const [coreSettings, loadingCoreSetting] = useDocumentData(
    uid ? db.documents.config.coreSettings : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const [dataPoint, setDataPoint] = useState<DocumentReference<UserInfo>>();
  const [userInfo, loadingUserInfo] = useDocumentData(dataPoint, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    if (!uid) {
      return;
    }
    const userRef = db.getUserInfoDataPoint(uid);
    setDataPoint(userRef);
  }, [uid]);

  const systemRole = userInfo?.systemRole;
  const systemRoleDefinition = coreSettings?.systemRoles?.find((role) => role.title === systemRole);
  const accessActivities = systemRoleDefinition?.accessActivities || [];

  const access = { ...defaultAccess };
  if (isSuperAdmin) {
    Object.keys(access).forEach((activityKey) => {
      access[activityKey as keyof typeof access] = true;
    });
  } else {
    accessActivities.forEach((activity) => {
      access[activity] = true;
    });
  }

  return {
    loading: loadingCoreSetting || loading || loadingUserInfo,
    access,
  };
}
