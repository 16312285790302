import { Context, createContext } from "react";

import { AccessActivities } from "../../../../common/db.types";
import { defaultAccess } from "./data";

export interface AccessContext {
  loading: boolean;
  access: Record<AccessActivities, boolean>;
}

export const accessContext: Context<AccessContext> = createContext<AccessContext>({
  loading: true,
  access: defaultAccess,
});
