import { lazy } from "react";
import { Route } from "react-router-dom";

const ListOfDepartmentsPage = lazy(() => import("./ListOfDepartmentsPage"));
const CreateDepartment = lazy(() => import("./CreateDepartment"));
const UsersDepartments = lazy(() => import("./UsersDepartments"));
const EditDepartment = lazy(() => import("./EditDepartment"));

export const companyStructureRouter = (
  <>
    <Route path="company-structure" element={<ListOfDepartmentsPage />} />
    <Route path="company-structure/:departmentId" element={<EditDepartment />} />
    <Route path="company-structure/users/:departmentId" element={<UsersDepartments />} />
    <Route path="company-structure/create" element={<CreateDepartment />} />;
  </>
);
